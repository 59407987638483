import { useMemo } from 'react';
import {
  FormProvider,
  useController,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';

import {
  type DtoMessageTemplateSettings,
  EnumsMessageDistributionChannel,
  EnumsMessageTemplateFormat,
  type ModelsMessageTemplateV2,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';
import { HeaderLayout } from '../../Header';
import { LPLogo } from '../../icons/LPLogo';
import { MessageTemplateFields } from '../Template';
import { uncheckedIndexAccess_UNSAFE } from '../../../utils/uncheckedIndexAccess_UNSAFE';

export function Header(props: {
  onCancel: () => void;
  onSubmit: () => void;
}): JSX.Element {
  const { formState } = useFormContext();

  return (
    <HeaderLayout
      containerClassName={'bg-admin-red'}
      fill
      left={
        <div className='flex items-center gap-4'>
          <LPLogo type='admin' />
        </div>
      }
      right={
        <>
          <button
            className='btn-secondary w-34 h-10 flex flex-row justify-center items-center'
            type='button'
            onClick={props.onCancel}
          >
            Cancel
          </button>

          <button
            className='btn-primary w-34 h-10 flex flex-row justify-center items-center'
            type='button'
            disabled={formState.isSubmitting}
            onClick={props.onSubmit}
          >
            {formState.isSubmitting ? 'Saving' : 'Save'}
          </button>
        </>
      }
    />
  );
}

function NameField() {
  const {
    field: { value, onChange },
    fieldState,
  } = useController<FormData, 'name'>({
    name: 'name',
    rules: { required: true, maxLength: 100 },
  });

  return (
    <div className='w-full flex flex-col gap-2'>
      <label className='font-bold'>Name (Required)</label>
      <input
        className={`${fieldState.error ? 'field-error' : 'field'}  w-1/2 mb-0`}
        type='text'
        value={value}
        onChange={onChange}
        placeholder='Must be 1 to 100 characters'
      />
    </div>
  );
}

function KeyField(props: { disabled: boolean }) {
  const {
    field: { value, onChange },
    fieldState,
  } = useController<FormData, 'key'>({
    name: 'key',
    rules: { required: true, pattern: /^[a-z0-9-]+$/ },
  });

  return (
    <div className='w-full flex flex-col gap-2'>
      <div>
        <p className='text-base font-bold'>Key</p>
        <p className='text-sms font-normal text-icon-gray'>
          A unique key to identify the template. Only lowercase letters,
          numbers, and hyphens are allowed.
        </p>
      </div>

      <input
        className={`${fieldState.error ? 'field-error' : 'field'} w-1/2 mb-0`}
        type='text'
        value={value}
        onChange={onChange}
        disabled={props.disabled}
        placeholder='Must be 1 to 100 characters'
      />
    </div>
  );
}

function DescriptionField() {
  const {
    field: { value, onChange },
    fieldState,
  } = useController<FormData, 'description'>({
    name: 'description',
    rules: { maxLength: 500 },
  });

  return (
    <div className='w-full flex flex-col gap-2'>
      <label className='font-bold'>Description</label>
      <textarea
        className={`${
          fieldState.error ? 'field-error' : 'field'
        } w-1/2 mb-0 p-2 min-h-30`}
        value={value}
        onChange={onChange}
        placeholder='Max 500 characters'
      />
    </div>
  );
}

function MessageVarsField() {
  const {
    field: { value, onChange },
    fieldState,
  } = useController<FormData, 'vars'>({
    name: 'vars',
    rules: { maxLength: 1000 },
  });
  return (
    <div className='w-full flex flex-col gap-2'>
      <div>
        <p className='text-base font-bold'>Variables</p>
        <p className='text-sms font-normal text-icon-gray'>
          A list of variables that can be used in the message template.
        </p>
      </div>
      <textarea
        className={`${
          fieldState.error ? 'field-error' : 'field'
        } w-1/2 mb-0 p-2 min-h-30`}
        value={value}
        onChange={onChange}
        placeholder={`recipientName=John Doe
link=https://example.com
`}
      />
    </div>
  );
}

function MessageTemplateSection(props: {
  settings?: DtoMessageTemplateSettings;
}) {
  const vars = useWatch<FormData, 'vars'>({ name: 'vars' });

  const translatedVars = useMemo(() => {
    const translated = uncheckedIndexAccess_UNSAFE({});

    if (!vars) return translated;

    for (const line of vars.split('\n')) {
      const [key, value] = line.split('=');
      if (!key || !value) continue;
      translated[key] = value;
    }
    return translated;
  }, [vars]);

  return (
    <MessageTemplateFields
      distributionChannelDisabled={!!props.settings}
      vars={translatedVars}
    />
  );
}

interface FormData {
  name: string;
  key: string;
  description: string;
  template: ModelsMessageTemplateV2;
  vars: string;
}

export function MessageTemplateSettingsEditor(props: {
  settings?: DtoMessageTemplateSettings;
  onSubmit: (settings: DtoMessageTemplateSettings) => void;
  onCancel: () => void;
}) {
  const { settings, onSubmit, onCancel } = props;

  const form = useForm<FormData>({
    defaultValues: {
      name: settings?.name || '',
      key: settings?.key || '',
      description: settings?.description || '',
      template: settings?.template || {
        type: EnumsMessageDistributionChannel.MessageDistributionChannelEmail,
        format: EnumsMessageTemplateFormat.MessageTemplateFormatMarkdown,
      },
      vars: settings?.vars || '',
    },
  });

  const handleSubmit = async (data: FormData) => {
    if (settings) {
      const resp = await apiService.message.updateTemplateSettings(
        settings.id,
        data
      );
      onSubmit(resp.data.settings);
      return;
    }

    const resp = await apiService.message.createTemplateSettings(data);
    onSubmit(resp.data.settings);
  };

  return (
    <FormProvider {...form}>
      <div className='w-full h-full flex flex-col'>
        <Header
          onCancel={onCancel}
          onSubmit={form.handleSubmit(handleSubmit)}
        />
        <div className='w-full flex-1 overflow-auto p-10 text-white flex flex-col gap-10'>
          <div className='w-full flex flex-col gap-4'>
            <NameField />
            <KeyField disabled={!!settings} />
            <DescriptionField />
            <MessageVarsField />
          </div>

          <MessageTemplateSection settings={settings} />
        </div>
      </div>
    </FormProvider>
  );
}
